import React, { useState, useEffect, useRef } from 'react';
import { db } from '../config/firebase';
import { getDocs, collection, query, orderBy, doc, deleteDoc, getDoc, DocumentReference, limit, startAfter } from 'firebase/firestore';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

function AdminStock() {
  const [productsData, setProductsData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const bottomOfPageRef = useRef(null);

  const productsCollectionRef = collection(db, 'products');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        bottomOfPageRef.current &&
        bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight &&
        !loading
      ) {
        // Load more products when user scrolls to the bottom
        fetchData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading]);

  const fetchData = async () => {
    if (loading) return; // Prevent concurrent executions
    setLoading(true);
    try {
      let q = query(productsCollectionRef, orderBy('importingDate', 'desc'), limit(10));
      if (lastDoc) {
        q = query(productsCollectionRef, orderBy('importingDate', 'desc'), startAfter(lastDoc), limit(10));
      }

      const productsSnapshot = await getDocs(q);

      if (!productsSnapshot.empty) {
        const newLastDoc = productsSnapshot.docs[productsSnapshot.docs.length - 1];

        const newProductsData = await Promise.all(productsSnapshot.docs.map(async (doc) => {
          const productData = { id: doc.id, ...doc.data() };
          // Resolve category reference to actual category data
          if (productData.category instanceof DocumentReference) {
            const categoryDoc = await getDoc(productData.category);
            productData.category = categoryDoc.exists() ? categoryDoc.data() : null;
          }
          if (productData.subCategory instanceof DocumentReference) {
            const categoryDoc = await getDoc(productData.subCategory);
            productData.subCategory = categoryDoc.exists() ? categoryDoc.data() : null;
          }
          return productData;
        }));

        setProductsData((prevProductsData) => {
          // Filter out any duplicates
          const productIds = new Set(prevProductsData.map(product => product.id));
          const filteredNewProductsData = newProductsData.filter(product => !productIds.has(product.id));
          return [...prevProductsData, ...filteredNewProductsData];
        });
        setLastDoc(newLastDoc);
      } else {
        setLastDoc(null); // No more documents to fetch
      }
    } catch (error) {
      console.error("Error fetching products data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (productId) => {
    // Prompt the user for confirmation
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
  
    // If user confirms, proceed with deletion
    if (confirmDelete) {
      const productDocRef = doc(db, "products", productId);
  
      try {
        // Delete the document
        await deleteDoc(productDocRef);
        alert("Product successfully deleted!");

        // Update productsData state by filtering out the deleted product
        setProductsData(prevProductsData => prevProductsData.filter(product => product.id !== productId));
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    } else {
      console.log("Deletion canceled by user.");
    }
  };

  return (
    <>
      <Navbar />
      <br /><br />
      <div className='global-container'>
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Id</th>
              <th>Name</th>
              <th>Price</th>
              <th>Category</th>
              <th>SubCategory</th>
              <th>Available</th>
              <th>Link</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {productsData.map((product) => (
              <tr key={product.id} style={ !product.available ? { backgroundColor: 'red', color: 'white' } : {} }>
                <td><a href={`/product/${product.id}`}><img src={product.img} alt='Product' /></a></td>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>{product.price}</td>
                <td>{product.category ? product.category.name : 'N/A'}</td>
                <td>{product.subCategory ? product.subCategory.name : 'N/A'}</td>
                <td>{product.available ? 'True' : 'False'}</td>
                <td>
                  <a href={product.link} 
                    target='_blank' 
                    rel="noreferrer" 
                    style={ !product.available ? { backgroundColor: 'red', color: 'white' } : {} }>
                      Link
                  </a>
                </td>
                <td><button onClick={() => handleDelete(product.id)}>Delete</button></td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <div className="loading-animation"></div>}
        <br />
        <div ref={bottomOfPageRef}></div>
      </div>
      <br /><br />
      <Footer />
    </>
  );
}

export default AdminStock;
