import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../config/firebase';
import { getDoc, doc, collection, updateDoc, query, getDocs, where } from 'firebase/firestore';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

function AdminCategoriesEdit() {
  const { id } = useParams();
  const [categoryName, setCategoryName] = useState('');
  const [categoryType, setCategoryType] = useState('');
  const [selectedParentCategory, setSelectedParentCategory] = useState('');
  const [categoriesData, setCategoriesData] = useState([]);

  const categoriesCollectionRef = collection(db, 'categories');
  const categoryDocRef = doc(categoriesCollectionRef, id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the current category data
        const categorySnapshot = await getDoc(categoryDocRef);
        const categoryData = {
          id: categorySnapshot.id,
          ...categorySnapshot.data()
        };
        setCategoryName(categoryData.name);
        setCategoryType(categoryData.type);
        setSelectedParentCategory(categoryData.parentCategory?.id || '');

        // Fetch parent categories if needed
        if (categoryData.type === 'subCategory') {
          const q = query(categoriesCollectionRef, where('type', '==', 'category'));
          const categoriesSnapshot = await getDocs(q);
          const catData = categoriesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setCategoriesData(catData);
        }
      } catch (error) {
        console.error("Error fetching categories data:", error);
      }
    };
    fetchData();
  }, [id]);

  async function updateCategory() {
    try {
      if (!categoryName || !categoryType) {
        alert('Category name and type are mandatory!');
        return;
      }

      if (categoryType === 'subCategory' && !selectedParentCategory) {
        alert('Parent category is mandatory for subcategories!');
        return;
      }

      const updatedCategoryData = {
        name: categoryName,
        type: categoryType
      };

      if (categoryType === 'subCategory') {
        updatedCategoryData.parentCategory = doc(db, 'categories', selectedParentCategory);
      }

      await updateDoc(categoryDocRef, updatedCategoryData);
      alert('Category updated!');
      window.location.href = '/admin/categories';
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        {!categoryName ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            <br /><br />
            <h2>Update Category</h2>
            <br />
            <p>Insert Category Name</p>
            <input
              type='text'
              name="name"
              id="name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <br /><br />
            <p>Category Type (Cannot be changed)</p>
            <select
              name="categoryType"
              id="categoryType"
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              disabled
            >
              <option value='category'>Category</option>
              <option value='subCategory'>Sub Category</option>
            </select>
            <br /><br />
            {categoryType === 'subCategory' && (
              <>
                <p>Select Parent Category</p>
                <select
                  name="parentCategory"
                  id="parentCategory"
                  value={selectedParentCategory}
                  onChange={(e) => setSelectedParentCategory(e.target.value)}
                >
                  <option disabled selected>Select Parent Category</option>
                  {categoriesData.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <br /><br />
              </>
            )}
            <button className='button' onClick={updateCategory}>Update</button>
            <br /><br />
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default AdminCategoriesEdit;
