import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { query, getDocs, addDoc, doc, collection, where } from 'firebase/firestore';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

function AdminCategoriesNew() {
  const [categoryName, setCategoryName] = useState('');
  const [categoryType, setCategoryType] = useState('');
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState('');

  const categoriesCollectionRef = collection(db, 'categories');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(categoriesCollectionRef, where('type', '==', 'category'));
        const categoriesSnapshot = await getDocs(q);
        const catData = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategoriesData(catData);
      } catch (error) {
        console.error("Error fetching categories data:", error);
      }
    };
    fetchData();
  }, []);

  async function newCategory() {
    try {

      // Validate the inputs
      if (!categoryName || !categoryType) {
        alert('Category name and type are mandatory!');
        return;
      }

      if (categoryType === 'subCategory' && !selectedParentCategory) {
        alert('Parent category is mandatory for subcategories!');
        return;
      }

      const q = query(categoriesCollectionRef, where("name", "==", categoryName));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        alert('Category with the same name already exists!');
        return;
      }

      const newCategoryData = {
        name: categoryName,
        type: categoryType
      };

      if (categoryType === 'subCategory') {
        newCategoryData.parentCategory = doc(db, 'categories', selectedParentCategory);
      }

      await addDoc(categoriesCollectionRef, newCategoryData);
      alert('Category created!');
      window.location.href = '/admin/categories';
    } catch(err) {
      console.error(err);
    }
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <>
          <br /><br />
          <h2>New Category</h2>
          <br />
          <p>Insert Category Name</p>
          <input
            type='text'
            name="name"
            id="name"
            placeholder='Insert Category Name'
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <br /><br />
          <p>Select Category Type</p>
          <select
            name="categoryType"
            id="categoryType"
            onChange={(e) => setCategoryType(e.target.value)}
          >
            <option disabled selected>Select Category Type</option>
            <option value='category'>Category</option>
            <option value='subCategory'>Sub Category</option>
          </select>
          <br /><br />
          {categoryType === 'subCategory' && (
            <>
              <p>Select Parent Category</p>
              <select
                name="parentCategory"
                id="parentCategory"
                onChange={(e) => setSelectedParentCategory(e.target.value)}
              >
                <option disabled selected>Select Parent Category</option>
                {categoriesData.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <br /><br />
            </>
          )}
          <button className='button' onClick={newCategory}>Create</button>
          <br /><br />
        </>
      </div>
      <Footer />
    </>
  );
}

export default AdminCategoriesNew;
