import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../config/firebase';
import { getDocs, collection, query, where, doc, getDoc, DocumentReference } from 'firebase/firestore';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import SideCategoriesList from '../components/SideCategoriesList';
import '../styles/searchBar.css';

const ProductsCategory = () => {
  const { categoryName } = useParams(); // Get the categoryName from URL parameters

  const categoriesCollectionRef = collection(db, 'categories');
  const productsCollectionRef = collection(db, 'products');

  const [categoriesData, setCategoriesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [numProductsLoaded, setNumProductsLoaded] = useState(10); // Number of products initially loaded
  const [loading, setLoading] = useState(false);
  const bottomOfPageRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(categoriesCollectionRef);
        const categoriesData = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCategoriesData(categoriesData);
      } catch (error) {
        console.error("Error fetching categories data:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const category = categoriesData.find(doc => doc.name === categoryName);
        if (!category) {
          setLoading(false);
          return;
        }
        const categoryDocRef = doc(db, 'categories', category.id);

        // Query products based on categoryId
        let q = query(productsCollectionRef, where("category", "==", categoryDocRef), where('available', '==', true));
        let querySnapshot = await getDocs(q);

        // If no category is found, check subcategories
        if (querySnapshot.docs.length === 0) {
          q = query(productsCollectionRef, where("subCategory", "==", categoryDocRef), where('available', '==', true));
          querySnapshot = await getDocs(q);
        }

        const productsData = await Promise.all(querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          if (productData.category instanceof DocumentReference) {
            const categoryDoc = await getDoc(productData.category);
            const subCategoryDoc = await getDoc(productData.subCategory);
            productData.category = categoryDoc.exists() ? categoryDoc.data() : null;
            productData.subCategory = subCategoryDoc.exists() ? subCategoryDoc.data() : null;
          }
          return productData;
        }));

        setProductsData(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };
    if (categoriesData.length > 0) {
      fetchProducts();
    }
  }, [categoryName, categoriesData]);

  const handleScroll = () => {
    if (
      bottomOfPageRef.current &&
      bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      // Load more products when user scrolls to the bottom
      setNumProductsLoaded(prevNumProducts => prevNumProducts + 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const ProductsGrid = ({ productsData, numProductsLoaded }) => {
    const productChunks = chunkArray(productsData.slice(0, numProductsLoaded), 4);

    return (
      <div className='column-sideCategories-right'>
        {productChunks.map((chunk, rowIndex) => (
          <div className='row' key={rowIndex}>
            {chunk.map((product) => (
              <div className='column-product' onClick={() => window.location.href = `/product/${product.id}`} key={product.id}>
                <img src={product.img} alt={product.name}></img>
                <br />
                <div className='product-informations'>
                  <p>{product.name}</p>
                  <br />
                  <h3>{product.price}</h3>
                  <br />
                  <a href={`/category/${product.subCategory.name}`}><p>{product.subCategory.name}</p></a>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <br /><br />
      <div className='global-container'>
        <h2>{categoryName}</h2>
        <br /><br />
        {loading ? (
          <div className="loading-animation"></div>
        ) : (
          <div className='row'>
            <div className='column-sideCategories-left'>
              <SideCategoriesList />
            </div>
            <ProductsGrid productsData={productsData} numProductsLoaded={numProductsLoaded} />
          </div>
        )}
        {loading && <div>Loading...</div>}
        <div ref={bottomOfPageRef}></div>
      </div>
      <br /><br />
      <Footer />
    </>
  );
};

export default ProductsCategory;