import React, { useState, useEffect } from "react";
import { db } from '../config/firebase';
import { getDocs, collection, query, where } from 'firebase/firestore';

const SideCategoriesList = () => {
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesCollectionRef = collection(db, 'categories');
        const categoriesSnapshot = await getDocs(categoriesCollectionRef);

        // Iterate through each category
        const categoriesData = [];
        for (const categoryDoc of categoriesSnapshot.docs) {
          const category = categoryDoc.data();
          category.id = categoryDoc.id;

          if (category.type === 'category') {
            // Fetch subcategories for each category
            const subcategoriesCollectionRef = collection(db, 'categories');
            const subcategoriesQuery = query(subcategoriesCollectionRef, where('parentCategory', '==', categoryDoc.ref));
            const subcategoriesSnapshot = await getDocs(subcategoriesQuery);

            const subcategories = subcategoriesSnapshot.docs.map(subcategoryDoc => ({
              id: subcategoryDoc.id,
              ...subcategoryDoc.data()
            }));

            // Add subcategories to the category
            category.subcategories = subcategories;
            categoriesData.push(category);
          }
        }

        // Set categoriesData state with the array of category data
        setCategoriesData(categoriesData);
      } catch (error) {
        console.error("Error fetching categories data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <h3>Categories</h3>
      <br />
      {categoriesData && 
        categoriesData.map((category) => (
          <div key={category.id}>
            <a href={`/category/${category.name}`}><p><b>{category.name}</b></p></a>
            {category.subcategories && 
              <>
                {category.subcategories.map((subcategory) => (
                  <a key={subcategory.id} href={`/category/${subcategory.name}`}><p>{' '}{subcategory.name}</p></a>
                ))}
                <br />
              </>
            }
          </div>
        ))
      }
    </>
  )
};

export default SideCategoriesList;
